import React from "react"

import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gplay from "../images/google-app-play-badge.png"
import Mockup from "../images/mockup-app.png"
import Aplay from "../images/apple-app-store-badge.png"
import { Col, Row } from "react-bootstrap"

const Download = () => {
  const intl = useIntl()

  return (
    <div className={"img-bg"}>
      <Layout>
        <SEO title="Download Aplikasi Ngebis" />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="shadow segment-table my-4 p-3 bg-white rounded-lg">
              <br />
              <h2 className="txt-primary text-center">
                <b style={{ color: "#ad1457" }}>
                  {intl.formatMessage({ id: "download-title" })}
                </b>
              </h2>
              <p style={{ margin: "2em" }}></p>
              <Row className={"p-4 text-center d-flex align-items-center"}>
                <Col sm={12} md={6} className={"text-right"}>
                  <img src={Mockup} alt="Mockup-ngebiz"/>
                </Col>
                <Col sm={12} md={6}>
                  <a href="https://play.google.com/store/apps/details?id=com.ngebis.ngebis_customer">
                    <img
                      src={Gplay}
                      alt="Gplay-ngebiz"
                      className={"width-5"}
                    />
                  </a>
                  <a href="https://apps.apple.com/id/app/ngebis-pesan-dan-lacak-bis/id1537058834">
                    <img
                      src={Aplay}
                      alt="gplay-ngebiz"
                      className={"width-5"}
                    />
                  </a>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Download
